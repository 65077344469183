<template>
	<section class="team gray-bg min-height-80vh">
        <div class="container">
            <div class="breadcrump">
                <h4 class="py-3 mb-4"><span class="text-muted fw-light"><a href="/">{{ $j('Homepage') }}</a> <i class="mdi mdi-chevron-right"></i></span> {{ $j('Leadership') }}</h4>
            </div>
            <!-- <div class="section-title text-center">
                <p>{{ $j('Leadership') }}</p>
                <h2 class="mb-4"></h2>
            </div> -->
            <div class="row justify-content-start pb-4">
                <span v-for="(item, index) in categories" :key="`categor_` + index" :class="`btn_6 cursor-pointer ` + (categoryId == item.id ? `btn_6_active ` : ``)+ `mr-2`" @click="getData(item.id)">
                    {{ item['categoryDefinition' + capitalizeFirstLetter()] }}
                </span>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 mb-4" v-for="(item, value) in staffs" :key="value">
                    <div class="member">
                        <div class="pic"><img :src="$store.state.mainBaseUrl + item.photoPath" class="img-fluid" alt=""></div>
                        <div class="member-info">
                            <h4>{{ item.firstName }} {{ item.middleName }}</h4>
                            <span>{{ item.position }}</span>
                            <div class="social">
                                <a href="tel: +998991009919">
                                    <i class="mdi mdi-phone"></i> {{ item.phoneNumber }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pt-3 d-flex justify-content-center" v-if="getPage > 1">
                <paginate 
                    v-model="getPage"
                    :page-count="pages"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="clickCallback"
                    :prev-text="'＜'"
                    :next-text="'＞'"
                    :container-class="'pagination'"
                    :page-class="'page-item'">
                </paginate>
                <form @submit.prevent="clickCallback(getPage)" >
                    <input v-model="getPage" type="number" class="page-input" />
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import Paginate from "vuejs-paginate";
export default {
    components: {
		Paginate,
	},
    data() {
		return {
            staffs: [],
            categories: [],
            categoryId: 0,

            limit: 20,
			offset: 0,
			pages: 0,
			getPage: 1,
        };
	},
	methods: {
        getData(catId) {
            this.categoryId = catId;
			this.$api.get("/query_service/api/v1/stuff/get_stuff?" + 
                    "categoryId=" + catId +
					"&offset=" + this.offset +
					"&limit=" + this.limit).then(response => {
				this.staffs = response.result.data;
                this.pages = Math.ceil(response.result.count / this.limit);
			},
			err => {
				this.$store.getters.errorParse(this, err);
			})
		},
		
        getCategories() {
			var _this = this;
			this.$api.get("/query_service/api/v1/stuff/get_categories").then(response => {
				_this.categories = response.result.data;
                this.getData(this.categoryId);
			},
			err => {
				this.$store.getters.errorParse(this, err);
			})
		},
        capitalizeFirstLetter() {
            let result = this.$store.state.langKod.charAt(0).toUpperCase() + this.$store.state.langKod.slice(1);
            return result.slice(0, -1)
        },
        clickCallback(page) {
			this.offset = (page - 1) * this.limit
			this.getData(this.categoryId);
		},
	},
	mounted() {
        this.getCategories();
	}
}
</script>